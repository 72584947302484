import axios from 'axios';
import emailIsValid from 'utils/emailIsValid';
import Fbq from 'clients/Fbq';
import { Listing, ListingAvailability } from 'clients/Outdoorsy';

const BASE_URL = '/api';

const VERSION = 'v1';
const API_URL = `${BASE_URL}/${VERSION}`;

const EscapodApi = {
  outdoorsy: {
    availability: async (id: string): Promise<ListingAvailability[]> =>
      (await axios.get(`${API_URL}/outdoorsy/availability?id=${id}`)).data,
    trailers: async (): Promise<Listing[]> =>
      (await axios.get(`${API_URL}/outdoorsy/trailers`)).data
  },
  rentals: {
    createBooking: async (data: {
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        FROM_DATE: string;
        TO_DATE: string;
        TOTAL: number;
        NIGHTS: number;
      };
    }): Promise<any> => {
      return (await axios.post(`${API_URL}/rentals/bookings/create`, data)).data;
    }
  },
  klaviyo: {
    subscribe: async (data: {
      listId?: string;
      email: string;
      firstName?: string;
      lastName?: string;
      phone?: string;
    }) => {
      return await axios.post(`${API_URL}/klaviyo/subscribe`, data);
    }
  },
  contact: {
    create: async (data: {
      templateParams: {
        NAME: string;
        EMAIL: string;
        PHONE: string;
        SUBJECT: string;
        MESSAGE: string;
      };
    }) => (await axios.post(`${API_URL}/contact/create`, data)).data
  },
  trailers: {
    fetch: async (): Promise<any[]> => (await axios.get(`${API_URL}/trailers`)).data
  },
  quotes: {
    create: async (data: {
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        ADDRESS: string;
        CITY: string;
        STATE: string;
        ZIP: string;
        PREFERRED_CONTACT_METHOD: string;
        MESSAGE: string;
        IMAGE: string;
        INTEREST: string;
        LEAD_TIME: string;
        TRAILER_NAME: string;
        TRAILER_BASE_PRICE: string;
        TRAILER_TOTAL_PRICE: string;
        TRAILER_VARIANTS: string;
        TRAILER_MODIFICATIONS: string;
      };
    }) => (await axios.post(`${API_URL}/quotes/create`, data)).data
  },
  reservations: {
    create: async (data: {
      stripeChargeId: string;
      templateParams: {
        FIRST_NAME: string;
        LAST_NAME: string;
        EMAIL: string;
        PHONE: string;
        ADDRESS: string;
        CITY: string;
        STATE: string;
        ZIP: string;
        MESSAGE: string;
        IMAGE: string;
        INTEREST: string;
        LEAD_TIME: string;
        TRAILER_NAME: string;
        TRAILER_BASE_PRICE: string;
        TRAILER_TOTAL_PRICE: string;
        TRAILER_VARIANTS: string;
        TRAILER_MODIFICATIONS: string;
      };
    }) => (await axios.post(`${API_URL}/reservations/create`, data)).data
  },
  orders: {
    verify: async (id: string, email: string) =>
      await (
        await axios.post(`${API_URL}/orders/verify`, { id, email })
      ).data,
    payments: {
      create: async (id: string, stripeChargeId: string) =>
        (await axios.post(`${API_URL}/orders/payments/create`, { id, stripeChargeId })).data
    }
  }
};

export default EscapodApi;
