import Cookies from 'js-cookie';

const captureCampaignData = () => {
  if (typeof window === 'undefined') return;

  const url = new URL(window.location.href);
  const ch = url.searchParams.get('ch') || ''; // channel
  const ca = url.searchParams.get('ca') || ''; // campaign
  const as = url.searchParams.get('as') || ''; // ad set
  const ad = url.searchParams.get('ad') || ''; // ad
  const pl = url.searchParams.get('pl') || ''; // placement
  const fbclid = url.searchParams.get('fbclid') || ''; // fbclid

  Cookies.set(`__ESCAPOD_CD_CH`, ch, { expires: 7 });
  Cookies.set(`__ESCAPOD_CD_CA`, ca, { expires: 7 });
  Cookies.set(`__ESCAPOD_CD_AS`, as, { expires: 7 });
  Cookies.set(`__ESCAPOD_CD_AD`, ad, { expires: 7 });
  Cookies.set(`__ESCAPOD_CD_PL`, pl, { expires: 7 });
  Cookies.set(`__ESCAPOD_CD_FBCLID`, fbclid, { expires: 7 });
};

export default captureCampaignData;
