import React from 'react';
import Shopify from 'clients/Shopify';
import Fbq from 'clients/Fbq';
import { LineItemInput, ShopifySession } from 'types';
// import PackageJson from 'package.json';

export const STORAGE_KEY = '__ESCAPOD__CART';

type Mutate = {
  initialize: () => Promise<void>;
  destroy: () => void;
  addLineItems: (lineItems: LineItemInput[]) => Promise<void>;
  removeLineItems: (lineItems: string[]) => Promise<void>;
};
const CartStateContext = React.createContext<ShopifySession | null>(null);
const CartMutateContext = React.createContext<Mutate | null>(null);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cart, setCart] = React.useState<ShopifySession | null>(null);
  const mutate: Mutate = {
    addLineItems: async function (lineItems) {
      if (!cart) return;
      setCart(await Shopify.session.addToCart(lineItems, cart.id));

      Fbq('AddToCart');
    },
    removeLineItems: async function (lineItems) {
      if (!cart) return;
      setCart(await Shopify.session.removeLineItems(lineItems, cart.id));
    },
    initialize: async function () {
      const persistedCartString = localStorage.getItem(STORAGE_KEY);
      const persistedCart: ShopifySession | null = !!persistedCartString
        ? JSON.parse(persistedCartString)
        : null;
      setCart(await Shopify.session.fetchOrCreate(persistedCart?.id || ''));
    },
    destroy: async function () {
      localStorage.removeItem(STORAGE_KEY);
      setCart(await Shopify.session.fetchOrCreate(''));
    }
  };

  React.useEffect(() => {
    mutate.initialize();
  }, []);

  React.useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cart));
  }, [cart]);

  return (
    <CartStateContext.Provider value={cart}>
      <CartMutateContext.Provider value={mutate}>{children}</CartMutateContext.Provider>
    </CartStateContext.Provider>
  );
};

export const useCartState = () => {
  const state = React.useContext(CartStateContext);

  if (state === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }

  return state;
};

export const useCartMutate = () => {
  const mutate = React.useContext(CartMutateContext);

  if (mutate === undefined) {
    throw new Error('useCartMutate must be used within a CartProvider');
  }

  return mutate;
};
