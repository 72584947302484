import React, { FC, createContext, useState, useContext } from 'react';

type TCompareContext = {
  active: string;
  setContext: (context: TCompareContext) => void;
};
const INITIAL_CONTEXT: TCompareContext = {
  active: '',
  setContext: () => {}
};

export const CompareContext = createContext(INITIAL_CONTEXT);
export const CompareContextProvider: FC = ({ children }) => {
  const [{ active }, setContext] = useState(INITIAL_CONTEXT);

  return (
    <CompareContext.Provider value={{ active, setContext }}>{children}</CompareContext.Provider>
  );
};

export const useCompareContext = () => {
  const compareContext = useContext(CompareContext);

  if (compareContext === undefined) {
    throw new Error('useCompareContext must be used within a CompareContextProvider');
  }

  return compareContext;
};

export default CompareContext;
