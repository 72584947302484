import { createContext, FC, useContext, useState, useEffect } from 'react';

export const STORAGE_KEY = '__ESCAPOD__NEWSLETTER';

export type TNewsletterContext = {
  campaign: string;
  setContext: (context: TNewsletterContext) => void;
};

const INITIAL_CONTEXT: TNewsletterContext = {
  campaign: '',
  setContext: () => {}
};

export const NewsletterContext = createContext<TNewsletterContext>(INITIAL_CONTEXT);

export const NewsletterContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState<TNewsletterContext>(INITIAL_CONTEXT);

  useEffect(() => {
    const persistedContextString = localStorage.getItem(STORAGE_KEY);
    const persistedContext: TNewsletterContext | null = !!persistedContextString
      ? JSON.parse(persistedContextString)
      : null;
    if (persistedContext) setContext(persistedContext);
  }, []);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(context));
  }, [context.campaign]);

  return (
    <NewsletterContext.Provider value={{ ...context, setContext }}>
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletterContext = () => {
  const context = useContext(NewsletterContext);

  if (context === undefined) {
    throw new Error('useNewsletterContext must be used within NewsletterContextProvider');
  }
  return context;
};
