import { request } from 'graphql-request';

import Gql from 'clients/Shopify/gql';
import Serializers from 'clients/Shopify/serializers';
import { ShopifySession, LineItemInput } from 'types';

const makeRequest = async <T>(
  query: string,
  variables: any | undefined,
  callback: (response: unknown) => T
) =>
  await request('https://escapod.myshopify.com/api/2021-10/graphql.json', query, variables, {
    'X-Shopify-Storefront-Access-Token': 'aec8453ff0bf88dfa0e1d37bfa960793'
  }).then(callback);

const Shopify = {
  products: {
    fetch: async function (): Promise<any[]> {
      const products: any[] = await makeRequest<any[]>(
        Gql.products,
        undefined,
        Serializers.products.fetch
      );

      return products;
    }
  },
  session: {
    fetchOrCreate: async function (checkoutId: string): Promise<ShopifySession> {
      let session: ShopifySession | null = null;

      if (checkoutId) {
        session = await makeRequest<ShopifySession>(
          Gql.checkoutFetch,
          { id: checkoutId },
          Serializers.session.fetch
        );
      }

      if (!session || !!session.completedAt) {
        session = await makeRequest<ShopifySession>(
          Gql.checkoutCreate,
          { input: {} },
          Serializers.session.create
        );
      }

      return session;
    },
    addToCart: async function (
      lineItems: LineItemInput[],
      checkoutId: string
    ): Promise<ShopifySession> {
      const session = await makeRequest<ShopifySession>(
        Gql.checkoutLineItemsAdd,
        { lineItems, checkoutId },
        Serializers.session.addToCart
      );

      return session;
    },
    removeLineItems: async function (
      lineItems: string[],
      checkoutId: string
    ): Promise<ShopifySession> {
      const session = await makeRequest<ShopifySession>(
        Gql.checkoutLineItemsRemove,
        { lineItemIds: lineItems, checkoutId },
        Serializers.session.removeLineItem
      );

      return session;
    }
  }
};

export default Shopify;
