import axios from 'axios';
import { addYears, format } from 'date-fns';

export type Booking = {
  checkout_steps_id: number;
};
export type BookingRequest = {
  renter: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  rental_id: string;
  from: string; // 'yyyy-MM-dd'
  to: string; // 'yyyy-MM-dd'
  items: any[]; // TO-DO

  abandoned?: boolean;

  status: string;
  campaign: string;
  source: string;
  medium: string;
};
export type Listing = {
  active_options: any[]; // TO-DO
  active_price: { day: number };
  cancel_policy: string;
  children_count: number;
  coachnet_ready: boolean;
  current_location_id: number;
  dealer: boolean;
  display_vehicle_type: string;
  favorite: boolean;
  favorite_count: number;
  group_on_map: boolean;
  home: {
    city: string;
    lat: number;
    lng: number;
    state: string;
  };
  id: string;
  images: {
    best: boolean;
    category: { name: string; slug: string };
    description: string;
    height: number | string | null;
    id: number;
    ignore_rejected: boolean;
    position: number;
    primary: boolean;
    skip_entrance: boolean;
    status: string;
    tags: string | string[];
    title: string;
    url: string;
    video: boolean;
    width: number | string | null;
  }[];
  instant_book: boolean;
  insurance_eligible: boolean;
  insurance_plan_id: number;
  insurance_state: string;
  name: string;
  owner_id: number;
  parent_id: number;
  presentment_currency: string;
  price: { day: number };
  primary_image_id: number;
  primary_image_url: string;
  require_roadside: boolean;
  score: number;
  score_count: number;
  settlement_currency: string;
  sleeps: number;
  style: string;
  tags: string | string[] | null;
  tax_rate_ids: number | null;
  type: string;
  vehicle: {
    length: number;
    make: string;
    model: string;
    year: number;
  };
  vehicle_title: string;
};
export type ListingAvailability = {
  rental_id: number;
  from: string;
  to: string;
  next_available: string;
  turnaround_days: number;
};

export const API_URL = 'https://api.outdoorsy.co/v0';
export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

// TO-DO: Add serializer to response
export const fetchTrailers = async (): Promise<Listing[]> => {
  const { data } = await axios.get(`${API_URL}/rentals?owner_id=248708`);
  return data;
};

// TO-DO: Add serializer to response
export const fetchAvailability = async (trailer: string): Promise<ListingAvailability[]> => {
  const now = formatDate(new Date());
  const then = formatDate(addYears(new Date(), 3));

  const { data } = await axios.get(
    `${API_URL}/availability?rental_id=${trailer}&from=${now}&to=${then}`
  );
  return data;
};

export const createBookingRequest = async (bookingRequest: BookingRequest): Promise<Booking> => {
  const { data } = await axios.post(`${API_URL}/bookings`, bookingRequest);
  return data;
};

export const makeCheckoutUrl = (booking: Booking): string => {
  return `https://checkout.wheelbasepro.com/checkout/${booking.checkout_steps_id}/questions`;
};
