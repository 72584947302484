import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  FormEvent
} from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  Elements as StripeProvider,
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';

import * as Gtag from 'clients/Gtag';
import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import base64 from 'utils/base64';
import formatInterestLabel from 'utils/formatInterestLabel';
import {
  CustomerInterestLevel,
  ProductLine,
  ProductLineModification,
  ProductLineModificationGroup
} from 'escapod';

import localforage from 'localforage';

export const STORAGE_KEY = '__ESCAPOD_BUILDER';

type BuilderCustomer = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  preferredContactMethod: string;
  message: string;
  newsletterOptIn: boolean;
  interest: CustomerInterestLevel;
};

type TBuilderMutate = {
  setCustomer: (customer: BuilderCustomer) => void;
  setCustomerErrors: (errors: Partial<BuilderCustomer & { form?: string }>) => void;
  selectTrailer: (id: string) => void;
  addModification: (modification: ProductLineModification) => void;
  removeModification: (modification: ProductLineModification) => void;
  conflictsForModification: (modification: ProductLineModification) => ProductLineModification[];
  selectVariantOptions: (variantOptions: { [key: string]: string }) => void;
  unmetDependenciesForModification: (
    modification: ProductLineModification
  ) => ProductLineModification[];
  selectedDependentsForModification: (
    modification: ProductLineModification
  ) => ProductLineModification[];
  setIsLoading: (isLoading: boolean) => void;
  setPaymentErrors: (errors: { form?: string }) => void;
  submit: () => Promise<void>;
  reserve: () => Promise<void>;
};
type TBuilderContext = {
  customer: BuilderCustomer;
  customerErrors: Partial<BuilderCustomer & { form?: string }>;
  trailers: ProductLine[];
  selectedTrailer: ProductLine | null;
  selectedVariantOptions: { [key: string]: string };
  modifications: ProductLineModification[];
  total: number;
  isLoading: boolean;
  paymentErrors: { form?: string };
};

const INITIAL_STATE: TBuilderContext = {
  customer: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    preferredContactMethod: '',
    message: '',
    newsletterOptIn: true,
    interest: 'quote'
  },
  customerErrors: {},
  trailers: [],
  selectedTrailer: null,
  selectedVariantOptions: {},
  modifications: [],
  total: 0,
  isLoading: false,
  paymentErrors: {}
};

export const BuilderContext = createContext(INITIAL_STATE);
export const BuilderMutate = createContext<TBuilderMutate | null>(null);

export const BuilderContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState(INITIAL_STATE);
  const router = useRouter();
  const stripe = useStripe();
  const elements = useElements();

  // TO-DO: Set as computed value
  const _getTotal = useCallback(
    (trailer: ProductLine | null, mods: ProductLineModification[]) =>
      mods.reduce((total, mod) => total + mod.price, trailer?.price || 0),
    []
  );
  const _setTrailers = (trailers: TBuilderContext['trailers']) =>
    setContext({ ...context, trailers });
  const _initialize = async () => _setTrailers(await EscapodApi.trailers.fetch());

  useEffect(() => {
    if (!context.selectedTrailer) return;
    const persist = async () => await localforage.setItem(STORAGE_KEY, JSON.stringify(context));
    persist();
  }, [context]);

  useEffect(() => {
    const rehydrate = async () => {
      const persistedContextString: string | null = await localforage.getItem(STORAGE_KEY);
      const persistedContext: TBuilderContext = !!persistedContextString
        ? JSON.parse(persistedContextString)
        : INITIAL_STATE;

      if (!!persistedContext?.selectedTrailer) setContext(persistedContext);
    };

    rehydrate();
  }, []);

  console.log('TRAILER MODS: ', context.modifications);

  const mutate: TBuilderMutate = useMemo(() => {
    return {
      selectTrailer: async id => {
        console.log('TRACK EVENT: selectTrailer', id);
        if (id === context?.selectedTrailer?._id) return;

        const persistedContextString: string | null = await localforage.getItem(STORAGE_KEY);
        const persistedContext: TBuilderContext = !!persistedContextString
          ? JSON.parse(persistedContextString)
          : INITIAL_STATE;

        if (!!id && id === persistedContext?.selectedTrailer?._id) {
          return setContext(persistedContext);
        }

        const selectedTrailer = context.trailers.find(trailer => trailer._id === id) || null;
        const firstOptions = selectedTrailer?.variantGroups.reduce(
          (selectedOptions, group) => ({
            ...selectedOptions,
            [group.name]: group.variants[0].name
          }),
          {}
        );
        setContext({
          ...context,
          selectedTrailer,
          selectedVariantOptions: firstOptions || {},
          modifications: [],
          total: _getTotal(selectedTrailer, context.modifications)
        });
      },
      addModification: mod => {
        setContext(state => ({
          ...state,
          modifications: [...state.modifications, mod],
          total: _getTotal(state.selectedTrailer, [...state.modifications, mod])
        }));

        Fbq('CustomizeProduct');
      },
      removeModification: mod => {
        setContext(state => {
          const modifications = state.modifications.filter(
            modification => modification.sku !== mod.sku
          );

          return {
            ...state,
            modifications,
            total: _getTotal(state.selectedTrailer, modifications)
          };
        });
      },
      selectVariantOptions: variantOptions => {
        setContext({
          ...context,
          selectedVariantOptions: { ...context.selectedVariantOptions, ...variantOptions }
        });
      },
      conflictsForModification: mod =>
        context.modifications.filter(modification => mod.conflicts?.includes(modification.sku)),
      unmetDependenciesForModification: mod => {
        if (!context.selectedTrailer) return [];

        const selectedModsBySku = context.modifications.map(modification => modification.sku);
        const trailerModifications = context.selectedTrailer.modificationGroups.reduce(
          (flattenedMods: ProductLineModification[], group: ProductLineModificationGroup) => [
            ...flattenedMods,
            ...group.modifications
          ],
          []
        );

        const unmetSkus =
          mod.dependencies?.filter(dependency => !selectedModsBySku.includes(dependency)) || [];

        return trailerModifications.filter(mod => unmetSkus.includes(mod.sku));
      },
      selectedDependentsForModification: mod => {
        if (!context.selectedTrailer) return [];

        const trailerModifications = context.selectedTrailer.modificationGroups.reduce(
          (flattenedMods: ProductLineModification[], group: ProductLineModificationGroup) => [
            ...flattenedMods,
            ...group.modifications
          ],
          []
        );
        const dependents = trailerModifications
          .filter(modification => modification.dependencies?.includes(mod.sku))
          .map(modification => modification.sku);

        return context.modifications.filter(modification => dependents.includes(modification.sku));
      },
      setCustomer: customer => setContext(context => ({ ...context, customer })),
      setCustomerErrors: errors => setContext(context => ({ ...context, customerErrors: errors })),
      setPaymentErrors: errors => setContext(context => ({ ...context, paymentErrors: errors })),
      setIsLoading: isLoading => setContext(context => ({ ...context, isLoading })),
      submit: async () => {
        console.log('TRACK EVENT: submit');
        const { selectedTrailer, customer, selectedVariantOptions, total, modifications } = context;

        if (!selectedTrailer || !customer.interest) return;

        // TO-DO: Create a helper for this
        const image = selectedTrailer.images?.find(image => {
          const unmatchedVariants = Object.values(selectedVariantOptions).reduce(
            (unmatchedVariants, option) => {
              const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

              return unmatchedVariants.filter((_, i) => i !== firstMatch);
            },
            image.values
          );

          return unmatchedVariants.length === 0;
        });

        Sentry.setUser({ email: customer.email });

        const request = {
          templateParams: {
            FIRST_NAME: customer.firstName,
            LAST_NAME: customer.lastName,
            EMAIL: customer.email,
            PHONE: customer.phone,
            ADDRESS: customer.address,
            CITY: customer.city,
            STATE: customer.state,
            ZIP: customer.zip,
            PREFERRED_CONTACT_METHOD: customer.preferredContactMethod,
            MESSAGE: customer.message,
            NEWSLETTER_OPT_IN: customer.newsletterOptIn,
            INTEREST: formatInterestLabel(customer.interest),
            LEAD_TIME: selectedTrailer.leadTime,
            IMAGE: image?.src || '',
            TRAILER_NAME: selectedTrailer.name,
            TRAILER_BASE_PRICE: selectedTrailer.price.toString(),
            TRAILER_TOTAL_PRICE: total.toString(),
            TRAILER_VARIANTS: Object.values(selectedVariantOptions).join(' / '),
            TRAILER_MODIFICATIONS: modifications // TO-DO: Use proper templating engine for this
              .map(modification => `${modification.name} - $${modification.price}<br>`)
              .join(''),

            CHANNEL: '',
            CAMPAIGN: '',
            AD_SET: '',
            AD: '',
            PLACEMENT: '',
            FBCLID: ''
          }
        };

        if (customer.newsletterOptIn) {
          EscapodApi.klaviyo
            .subscribe({
              firstName: customer.firstName,
              lastName: customer.lastName,
              email: customer.email
            })
            .catch(console.error);
        }

        return EscapodApi.quotes
          .create(request)
          .then(() => {
            if (
              process.env.NODE_ENV !== 'development' &&
              process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging'
            ) {
              Fbq('Lead');
              Gtag.event('conversion', {
                send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
                transaction_id: window.btoa(customer.email),
                event_callback: () => {}
              });
            }
          })
          .catch(err => {
            Sentry.withScope(scope => {
              scope.setExtra('request', request);
              Sentry.captureException(err);
            });
            setContext(context => ({
              ...context,
              customerErrors: {
                form: 'There was an unexpected error while submitting your quote. Please contact sales@escapod.us directly or try again later.'
              }
            }));
          })
          .finally(() => {
            setContext(context => ({ ...context, isLoading: false }));
            setTimeout(() => localforage.removeItem(STORAGE_KEY), 0);
          });
      },
      reserve: async () => {
        const { selectedTrailer, customer, modifications, total, selectedVariantOptions } = context;
        if (!stripe || !elements || !selectedTrailer || !customer.interest) return;

        const card = elements.getElement(CardElement);
        if (!card) {
          return setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: { form: `Please enter required card data.` }
          }));
        }

        const image = selectedTrailer.images?.find(image => {
          const unmatchedVariants = Object.values(selectedVariantOptions).reduce(
            (unmatchedVariants, option) => {
              const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

              return unmatchedVariants.filter((_, i) => i !== firstMatch);
            },
            image.values
          );

          return unmatchedVariants.length === 0;
        });

        setContext(context => ({ ...context, isLoading: true }));

        const result = await stripe.createToken(card).catch(err => {
          Sentry.captureException(err);
          setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: {
              form: `Unexpected Error: We could not complete your reservation. You may contact sales@escapod.us directly or try again later.`
            }
          }));
        });

        if (!result?.token) {
          return setContext(context => ({
            ...context,
            isLoading: true,
            paymentErrors: { form: `Please enter required card data.` }
          }));
        }

        Fbq('AddPaymentInfo');
        Sentry.setUser({ email: customer.email });

        const request = {
          stripeChargeId: result.token.id,
          templateParams: {
            FIRST_NAME: customer.firstName,
            LAST_NAME: customer.lastName,
            EMAIL: customer.email,
            PHONE: customer.phone,
            ADDRESS: customer.address,
            CITY: customer.city,
            STATE: customer.state,
            ZIP: customer.zip,
            MESSAGE: customer.message,
            PREFERRED_CONTACT_METHOD: customer.preferredContactMethod,
            NEWSLETTER_OPT_IN: customer.newsletterOptIn,
            INTEREST: formatInterestLabel('reserve'),
            LEAD_TIME: selectedTrailer.leadTime,
            IMAGE: image?.src || '',
            TRAILER_NAME: selectedTrailer.name,
            TRAILER_BASE_PRICE: selectedTrailer.price.toString(),
            TRAILER_TOTAL_PRICE: total.toString(),
            TRAILER_VARIANTS: Object.values(selectedVariantOptions).join(' / '),
            TRAILER_MODIFICATIONS: modifications // TO-DO: Use proper templating engine for this
              .map(modification => `${modification.name} - $${modification.price}<br>`)
              .join(''),

            CHANNEL: '',
            CAMPAIGN: '',
            AD_SET: '',
            AD: '',
            PLACEMENT: '',
            FBCLID: ''
          }
        };

        if (customer.newsletterOptIn) {
          EscapodApi.klaviyo
            .subscribe({
              firstName: customer.firstName,
              lastName: customer.lastName,
              email: customer.email
            })
            .catch(console.error);
        }

        EscapodApi.reservations
          .create(request)
          .then(() => {
            if (process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging') {
              Fbq('Lead');
              Gtag.event('conversion', {
                send_to: 'AW-814232277/ccFdCL7bqKYDENXloIQD',
                transaction_id: base64(customer.email),
                event_callback: () => {}
              });
            }
          })
          .catch(err => {
            Sentry.withScope(scope => {
              scope.setExtra('request', request);
              scope.setExtra('stripe_result', result);
              Sentry.captureException(err);
            });
            setContext(context => ({
              ...context,
              customerErrors: {
                form: 'There was an unexpected error while submitting your quote. Please contact sales@escapod.us directly or try again later.'
              }
            }));
          })
          .finally(() => {
            setContext(context => ({ ...context, isLoading: false }));
            setTimeout(() => localforage.removeItem(STORAGE_KEY), 0);
          });
      }
    };
  }, [context]);

  useEffect(() => {
    _initialize();
  }, []);

  return (
    <BuilderContext.Provider value={context}>
      <BuilderMutate.Provider value={mutate}>{children}</BuilderMutate.Provider>
    </BuilderContext.Provider>
  );
};

export const useBuilderContext = () => {
  const builderContext = useContext(BuilderContext);

  if (builderContext === undefined) {
    throw new Error('useBuilderContext must be used within a BuilderContextProvider');
  }

  return builderContext;
};

export const useBuilderMutate = () => {
  const mutate = useContext(BuilderMutate);

  if (mutate === undefined) {
    throw new Error('useBuilderMutate must be used within a BuilderContextProvider');
  }

  return mutate;
};

export default BuilderContextProvider;
