import get from 'lodash/get';

import { ShopifySession } from 'types';

const emptyArr: any[] = [];
const emptyObj = {};

const Serializers = {
  products: {
    fetch: (response: unknown): any[] => {
      const edges: unknown[] = get(response, 'products.edges', emptyArr);
      const products = edges.map(edge => {
        const node: unknown = get(edge, 'node', emptyObj);
        const title = get(node, 'title', '');
        const handle = get(node, 'handle', '');
        const images = get(node, 'images.edges', emptyArr).map((edge: unknown) => {
          const image = get(edge, 'node', emptyObj);
          const src = get(image, 'originalSrc', '');

          return { src };
        });
        const gridImage = !!images.length && images[0];
        const availableForSale = get(node, 'availableForSale', false);
        const id = get(node, 'id', false);
        const productType = get(node, 'productType', false);
        const tags = get(node, 'tags', emptyArr);

        const options = get(node, 'options', emptyArr);
        const variants = get(node, 'variants.edges', []).map((variant: any) => {
          const variantNode = get(variant, 'node', {});
          const { id, price, title, availableForSale, selectedOptions } = variantNode;
          return {
            id,
            price,
            title,
            available: availableForSale,
            selectedOptions
          };
        });

        return {
          title,
          handle,
          images: {
            grid: gridImage
          },
          variants,
          id,
          productType,
          availableForSale,
          tags,
          options
        };
      });

      return products;
    }
  },
  session: {
    create: (response: unknown): ShopifySession => {
      return {
        ...get(response, 'checkoutCreate.checkout'),
        lineItems: get(response, 'checkoutCreate.checkout.lineItems.edges', emptyArr).map(
          (edge: unknown) => {
            return get(edge, 'node', emptyObj);
          }
        )
      };
    },
    fetch: (response: unknown): ShopifySession => {
      return {
        ...get(response, 'node'),
        lineItems: get(response, 'node.lineItems.edges', emptyArr).map((edge: unknown) => {
          return get(edge, 'node', emptyObj);
        })
      };
    },
    addToCart: (response: unknown): ShopifySession => {
      return {
        ...get(response, 'checkoutLineItemsAdd.checkout'),
        lineItems: get(response, 'checkoutLineItemsAdd.checkout.lineItems.edges', emptyArr).map(
          (edge: unknown) => {
            return get(edge, 'node', emptyObj);
          }
        )
      };
    },
    removeLineItem: (response: unknown): ShopifySession => {
      return {
        ...get(response, 'checkoutLineItemsRemove.checkout'),
        lineItems: get(response, 'checkoutLineItemsRemove.checkout.lineItems.edges', emptyArr).map(
          (edge: unknown) => {
            return get(edge, 'node', emptyObj);
          }
        )
      };
    }
  }
};

export default Serializers;
