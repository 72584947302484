const LABELS: { [key: string]: string } = {
  quote: 'Just Looking / Quote',
  appointment: 'Want to Talk / Appointment Requested',
  reserve: 'Ready to Buy / Reservation'
};

const formatInterestLabel = (input: string): string => {
  return LABELS[input] || LABELS.quote;
};

export default formatInterestLabel;
