import React, { FC, useState, useContext, createContext } from 'react';

export type TSearchContext = any;

export const INITIAL_CONTEXT: TSearchContext = {
  search: '',
  accordionBlocks: {},
  setSearch: () => {}
};

export const SearchContext = createContext<TSearchContext>(INITIAL_CONTEXT);
export const SearchContextProvider: FC = ({ children }) => {
  const [{ search, accordionBlocks }, setSearch] = useState<any>(INITIAL_CONTEXT);

  return (
    <SearchContext.Provider value={{ search, accordionBlocks, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);

  if (searchContext === undefined) {
    throw new Error('useSearchContext must be used within a SearchContextProvider');
  }

  return searchContext;
};
