import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import {
  parse,
  differenceInDays,
  format,
  isBefore,
  isAfter,
  isSameDay,
  subDays,
  addDays
} from 'date-fns';

import * as Outdoorsy from 'clients/Outdoorsy';
import * as Gtag from 'clients/Gtag';
import base64 from 'utils/base64';
import EscapodApi from 'clients/Escapod';

const isDuring = (dates: Date | Date[], from: Date, to: Date): boolean => {
  const _isDuring = (date: Date, from: Date, to: Date) => {
    if (isSameDay(date, to) || isSameDay(date, from)) return true;
    return isAfter(date, from) && isBefore(date, to);
  };

  return Array.isArray(dates)
    ? dates.some(date => _isDuring(date, from, to))
    : _isDuring(dates, from, to);
};

type TRentalBookingsContext = {
  trailers: Outdoorsy.Listing[];
  availability: { [key: string]: Outdoorsy.ListingAvailability[] } | null;
  pickupDate: Date | null;
  dropoffDate: Date | null;
  total: number;
  selectedAvailability: Outdoorsy.ListingAvailability | null;
  firstAvailablePickup: Date | null;
  firstAvailableDropoff: Date | null;

  status: 'IDLE' | 'FULFILLED' | 'PENDING' | 'REJECTED';

  isPickupAvailable: (date: Date, dropoff: Date | null) => boolean;
  isDropoffAvailable: (date: Date, pickup: Date | null) => boolean;

  trailerId: number;
  error: string;
};
type TRentalBookingsMutate = {
  setPickupDate: (date: Date | null) => void;
  setDropoffDate: (date: Date | null) => void;
  clearPickupDate: () => void;
  clearDropoffDate: () => void;
  startBooking: (customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }) => void;
};

const INITIAL_CONTEXT: TRentalBookingsContext = {
  trailers: [],
  availability: null,
  pickupDate: null,
  dropoffDate: null,
  total: 0,
  selectedAvailability: null,
  firstAvailableDropoff: null,
  firstAvailablePickup: null,

  status: 'IDLE',

  isPickupAvailable: () => false,
  isDropoffAvailable: () => false,

  trailerId: 327231, // Hard-coded for now
  error: ''
};

export const RentalBookingsContext = createContext<TRentalBookingsContext>(INITIAL_CONTEXT);
export const RentalBookingsMutate = createContext<TRentalBookingsMutate | null>(null);

export const RentalBookingsContextProvider: FC = ({ children }) => {
  const [context, setContext] = useState(INITIAL_CONTEXT);

  const mutate: TRentalBookingsMutate = {
    setPickupDate: pickupDate => setContext({ ...context, pickupDate }),
    setDropoffDate: dropoffDate => setContext({ ...context, dropoffDate }),
    clearPickupDate: () => setContext({ ...context, pickupDate: null }),
    clearDropoffDate: () => setContext({ ...context, dropoffDate: null }),
    startBooking: useCallback(
      customer => {
        if (!context.pickupDate || !context.dropoffDate) return;

        const templateParams = {
          FIRST_NAME: customer.firstName,
          LAST_NAME: customer.lastName,
          EMAIL: customer.email,
          PHONE: customer.phone,
          FROM_DATE: Outdoorsy.formatDate(context.pickupDate),
          TO_DATE: Outdoorsy.formatDate(context.dropoffDate),
          TOTAL: context.total,
          NIGHTS:
            !!context.pickupDate && !!context.dropoffDate
              ? differenceInDays(context.dropoffDate, context.pickupDate)
              : 0
        };

        EscapodApi.rentals
          .createBooking({ templateParams })
          .then(() => {
            setContext({ ...context, status: 'FULFILLED' });
          })
          .catch(error => {
            // TO-DO: Sentry
            setContext({ ...context, status: 'REJECTED' });
          });

        // TO-DO: Outdoorsy deprecated, remove asap
        //
        // Outdoorsy.createBookingRequest({
        //   renter: {
        //     first_name: customer.firstName,
        //     last_name: customer.lastName,
        //     email: customer.email,
        //     phone: customer.phone
        //   },
        //   rental_id: context.trailerId.toString(),
        //   from: Outdoorsy.formatDate(context.pickupDate),
        //   to: Outdoorsy.formatDate(context.dropoffDate),
        //   status: 'negotiating',
        //   abandoned: false,

        //   // TO-DO: Add add-ons and campaign data
        //   items: [],
        //   campaign: '',
        //   source: '',
        //   medium: ''
        // })
        //   .then(booking => {
        //     console.log('Successfully created booking', booking);
        //     // TO-DO: Reset context

        //     Gtag.event('conversion', {
        //       send_to: 'AW-814232277/zNn5CJOA39wBENXloIQD',
        //       event_callback: () => {}
        //     });
        //     Gtag.event('conversion', {
        //       send_to: 'AW-814232277/BrFmCPzUo6YDENXloIQD',
        //       transaction_id: base64(customer.email),
        //       event_callback: () => {}
        //     });

        //     setTimeout(() => {
        //       window.location.href = Outdoorsy.makeCheckoutUrl(booking);
        //     }, 5000);
        //   })
        //   .catch(err => {
        //     console.error(err.error);
        //     setContext({ ...context, error: err.error });
        //   });
      },
      [context]
    )
  };

  const isPickupAvailable = useCallback(
    (date: Date, dropoff: Date | null) => {
      if (isSameDay(date, new Date())) return false;

      const ranges = context?.availability?.[327231] || []; // Hard-coded until Rentals 2.0 comes out
      const twoDaysBeforeDropoff = !!dropoff ? subDays(dropoff, 2) : null;
      if (
        !!twoDaysBeforeDropoff &&
        !isSameDay(date, twoDaysBeforeDropoff) &&
        isAfter(date, twoDaysBeforeDropoff)
      ) {
        return false;
      }

      const tripAfterDropoff = dropoff
        ? ranges.findIndex(range => {
            const from = parse(range.from, 'yyyy-MM-dd', new Date());
            return isBefore(dropoff, from);
          })
        : -1;
      const tripBefore = tripAfterDropoff > 0 ? ranges[tripAfterDropoff - 1] : null;
      const tripBeforeEndDate = !!tripBefore
        ? parse(tripBefore.to, 'yyyy-MM-dd', new Date())
        : null;
      if (
        !!tripBeforeEndDate &&
        (isSameDay(date, tripBeforeEndDate) || isBefore(date, tripBeforeEndDate))
      ) {
        return false;
      }

      const dayAfter = addDays(date, 1);
      const twoDaysAfter = addDays(date, 2);

      const dateOverlapsExistingTrip = ranges.some(range => {
        const from = parse(range.from, 'yyyy-MM-dd', new Date());
        const to = parse(range.to, 'yyyy-MM-dd', new Date());

        return isDuring([date, dayAfter, twoDaysAfter], from, to);
      });

      return !dateOverlapsExistingTrip;
    },
    [context]
  );

  const isDropoffAvailable = useCallback(
    (date: Date, pickup: Date | null) => {
      const ranges = context?.availability?.[327231] || []; // Hard-coded until Rentals 2.0 comes out

      // Ensures trips are 2+ nights long
      const twoDaysAfterPickup = !!pickup ? addDays(pickup, 2) : null;
      if (!!twoDaysAfterPickup && isBefore(date, twoDaysAfterPickup)) return false;

      // Ensures contiguous trips
      const tripAfterPickup = pickup
        ? ranges.find(range => {
            const from = parse(range.from, 'yyyy-MM-dd', new Date());
            return isBefore(pickup, from);
          })
        : null;
      const tripAfterStartDate = !!tripAfterPickup
        ? parse(tripAfterPickup.from, 'yyyy-MM-dd', new Date())
        : null;

      if (
        !!tripAfterStartDate &&
        (isSameDay(date, tripAfterStartDate) || isAfter(date, tripAfterStartDate))
      ) {
        return false;
      }

      // Ensures there's an open pickup date that isn't today
      const dayBefore = subDays(date, 1);
      const twoDaysBefore = subDays(date, 2);
      if ([date, dayBefore, twoDaysBefore].some(_date => isSameDay(_date, new Date()))) {
        return false;
      }

      const dateOverlapsExistingTrip = ranges.some(range => {
        const from = parse(range.from, 'yyyy-MM-dd', new Date());
        const to = parse(range.to, 'yyyy-MM-dd', new Date());

        return isDuring([date, dayBefore, twoDaysBefore], from, to);
      });

      return !dateOverlapsExistingTrip;
    },
    [context]
  );

  const computed = {
    total: useMemo(() => {
      const pricePerDay =
        (context.trailers.length && context.trailers[0]?.active_price?.day) / 100 || 0;
      return !!context.pickupDate && !!context.dropoffDate
        ? differenceInDays(context.dropoffDate, context.pickupDate) * pricePerDay
        : 0;
    }, [context]),
    firstAvailablePickup: useMemo(() => {
      let cursor: Date = new Date();
      while (!isPickupAvailable(cursor, context.dropoffDate)) cursor = addDays(cursor, 1);
      return cursor;
    }, [context]),
    firstAvailableDropoff: useMemo(() => {
      let cursor: Date = context.pickupDate || new Date();
      while (!isDropoffAvailable(cursor, context.pickupDate)) cursor = addDays(cursor, 1);
      return cursor;
    }, [context]),

    isPickupAvailable,
    isDropoffAvailable
  };

  const _initialize = async () => {
    const trailers = await EscapodApi.outdoorsy.trailers();

    const availability: TRentalBookingsContext['availability'] = {};
    for (let i = 0; i < trailers.length; i++) {
      const id = trailers[i].id;
      availability[id] = await EscapodApi.outdoorsy.availability(id);
    }

    setContext({ ...context, trailers, availability });
  };

  useEffect(() => {
    _initialize();
  }, []);

  return (
    <RentalBookingsContext.Provider value={{ ...context, ...computed }}>
      <RentalBookingsMutate.Provider value={mutate}>{children}</RentalBookingsMutate.Provider>
    </RentalBookingsContext.Provider>
  );
};

export const useRentalBookingsContext = () => {
  const rentalBookingsContext = useContext(RentalBookingsContext);

  if (rentalBookingsContext === undefined) {
    throw new Error('useRentalBookingsContext must be used within a RentalBookingsContextProvider');
  }

  return rentalBookingsContext;
};

export const useRentalBookingsMutate = () => {
  const rentalBookingsMutate = useContext(RentalBookingsMutate);

  if (rentalBookingsMutate === undefined) {
    throw new Error('useRentalBookingsMutate must be used within a RentalBookingsMutateProvider');
  }

  return rentalBookingsMutate;
};

export default RentalBookingsContext;
