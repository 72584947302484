export const pageview = (url: string) => {
  const gtag =
    typeof window !== 'undefined' && !!(window as any).gtag ? (window as any).gtag : () => {};

  gtag('config', 'UA-113719894-1', {
    page_path: url
  });
};

export const event = (action: string, params: any) => {
  const gtag =
    typeof window !== 'undefined' && !!(window as any).gtag ? (window as any).gtag : () => {};

  gtag('event', action, params);
};
