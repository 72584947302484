const track = (eventCode: string, params?: { [key: string]: any }): void => {
  const fbq =
    typeof window !== 'undefined' && !!(window as any).fbq ? (window as any).fbq : () => {};

  try {
    params ? fbq('track', eventCode, params) : fbq('track', eventCode);
  } catch (err) {
    // TO-DO: Catch in Sentry
    console.log('FBQ Error');
    console.error(err);
  }
};

export default track;
