import React, { FC, createContext, useState, useContext } from 'react';

type TNavContext = {
  theme: 'white' | 'black';
  setContext: (context: TNavContext) => void;
};
const INITIAL_CONTEXT: TNavContext = {
  theme: 'black',
  setContext: () => {}
};

export const NavContext = createContext(INITIAL_CONTEXT);
export const NavContextProvider: FC = ({ children }) => {
  const [{ theme }, setContext] = useState(INITIAL_CONTEXT);

  return <NavContext.Provider value={{ theme, setContext }}>{children}</NavContext.Provider>;
};

export const useNavContext = () => {
  const navContext = useContext(NavContext);

  if (navContext === undefined) {
    throw new Error('useNavContext must be used within a NavContextProvider');
  }

  return navContext;
};

export default NavContext;
